import Vue from 'vue';
import VueRouter from 'vue-router';
import home from '@/views/home';
import goodsDetail from '@/views/goodsDetail';
import goodsDetailTest from '@/views/goodsDetailTest';
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/goodsDetail',
    name: 'goodsDetail',
    component: goodsDetail
  },
  {
    path: '/goodsDetailTest',
    name: 'goodsDetailTest',
    component: goodsDetailTest
  }
];

const router = new VueRouter({
  mode: 'history',
  //mode: 'hash',
  base: process.env.VUE_APP_URL,
  routes
});

export default router;
